import firebase from '../config/firebase.config'

export default class Artista {

    constructor() {
    }

    static async getArtista() {
        let lstArtistas = []
        const resonse = await firebase.firestore().collection("Artistas").get();
        resonse.forEach(doc => {
            lstArtistas.push(doc.data());
        })
        return lstArtistas;
    }
}