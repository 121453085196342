import React, { useState } from 'react'
import styled from 'styled-components';

const StyledButton = styled.div`
  text-align: center;
  font-family: MonumentExtended; 
  font-size: 20px; 
  border: 2px solid white;
  padding: 10px;
  border-radius: 15px;
  border-color: black;
  background: white;

  :hover{
    background-color: white;
    color: black;
    border-color: black;
    cursor: pointer;
  }
@media (max-width: 575.98px) { 
    font-size: 15px;
  }

`;

const BookingInquiry = ({ setShow }) => {

  return(
    <StyledButton
      onClick={() => setShow(true)}
    >
      BOOKING INQUIRY 
    </StyledButton>
  );
}

export default BookingInquiry;
