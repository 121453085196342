import React from "react";
import { Container, Row, Col } from "rsuite";
import { Gradient, GradientWebm, GradientOGG } from "../../assets";
import styled from "styled-components";

const StyledHome = styled.div`
  & > video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }

  .background-overlay {
    line-height: 15px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f1f1f1;
    width: 100%;
    height: 100%;
  }

  .background-overlay img {
    width: 350px;
  }

  .bold {
    font-family: "MonumentExtended";
    font-weight: 900;
    font-size: 15px;
  }

  .texto {
    font-size: 10px;
    margin-top: -9%;
  }
`;

const Home = () => {
  return (
    <StyledHome>
      <video autoPlay muted loop playsInline>
        <source src={Gradient} type="video/mp4" />
        <source src={GradientOGG} type="video/ogg" />
        <source src={GradientWebm} type="video/webm" />
      </video>
      <div className="background-overlay">
        <div>
          <span className="bold">HUMAN TALENT</span>
          <div className="texto">
            <br />
            NO PODEMOS DEFINIR EL OBJETO
            <br />
            O FIN, SIN UN SUJETO QUE
            <br />
            PERCIBA NUESTRAS ACCIONES
          </div>
        </div>
      </div>
    </StyledHome>
  );
};

export default Home;
