import React from "react";
import styled from "styled-components";
import f1 from "../../assets/img/fondo1.jpg";

const StyledCompetences = styled.div`
  /* iPhone */

  background-image: url("${f1}");
  min-height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;

  .derechos {
    color: white;
    font-size: 9px;
    text-align: center;
    padding-top: 5%;
    padding-bottom: 2%;
  }

  .COMPETENCES1 {
    font-size: 34px;
    color: white;
    text-align: justify;
    font-family: "MonumentExtended";
    font-weight: bold;
  }

  .textborder {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
  }

  .parrafo {
    font-size: 10px;
    color: white;
    text-align: center;
    padding: 0 8%;
    line-height: 1.2;
    text-align: center;
  }

  .rotar {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .margen {
    height: 130px;
    border-radius: 20px;
    border: solid 2px #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2% 9%;
  }
  .titles {
    font-size: 22px;
    font-weight: bold;
  }

  .competence3 {
    width: 64%;
  }

  /* iPad */
  @media (min-width: 576px) and (max-width: 1165px) {
    .parrafo {
      font-size: 14px !important;
    }
    .titles {
      font-size: 32px !important;
    }
    .COMPETENCES1 {
      font-size: 41px !important;
    }
    .margen {
      border-radius: 42px !important;
    }

    .derechos {
      padding-bottom: -20%;
      padding-top: -20%;
    }
  }

  /* Desktop  */
  @media (min-width: 1165px) {
    .parrafo {
      font-size: 18px !important;
    }
    .titles {
      font-size: 38px !important;
    }
    .COMPETENCES1 {
      font-size: 48px !important;
    }
    .margen {
      border-radius: 42px !important;
      margin: 4% 18% !important;
      height: 198px !important;
    }

    .derechos {
      padding-bottom: 3%;
      padding-top: -10%;
    }
  }
`;

const Competences = () => {
  return (
    <StyledCompetences>
      <div className="COMPETENCES1 textborder " style={{ paddingTop: "20px" }}>
        COMPETENCES
      </div>

      <div>
        <div className="margen">
          <div className="titles textborder ">ACID MANAGMENT</div>
          <div className="parrafo ">
            Acid Management is a talent management company that focus on
            bringing artistic projects to the next level. The good reputation
            and contacts over the industry helos ACID Management to accomplish
            their artist's goals.
          </div>
        </div>

        <div className="margen">
          <div className="titles textborder ">ACID BOOKING SERVICES</div>
          <div className="parrafo  ">
            Acid Management is a talent management company that focus on
            bringing artistic projects to the next level. The good reputation
            and contacts over the industry helos ACID Management, to accomplish
            their artist's goals.
          </div>
        </div>

        <div className="margen">
          <div className="titles textborder ">ACID LOGISTICS</div>
          <div className="parrafo  ">
            We provide planning and execution services for events like
            Festivals, Racing, government and corporate.
            <br />
            <br />
            We get shit done.
          </div>
        </div>
      </div>

      <div className="COMPETENCES1 textborder rotar "> COMPETENCES</div>
      <div className="derechos">©2021 ALL RIGHTS RESERVED ACID</div>
    </StyledCompetences>
  );
};
export default Competences;
