import "./index.css";
import "rsuite/dist/styles/rsuite-default.css";
import { Switch, Router, Route } from "react-router-dom";
import { Contact, Home, AboutAcid, Roster, Competences  } from "./components/Pages";
import Menu from "./components/Menu";
import { Fragment, useState } from "react";
import styled from "styled-components";
import { Carlos } from "./components/Artist/Carlos";
import { Chile } from "./components/Artist/Chile";
import {  KillTheClowns } from "./components/Artist/KillTheClowns";
import { CriminalSounds } from "./components/Artist/CriminalSounds";
import { Madariaga } from "./components/Artist/Madariaga";
import { Tadeo } from "./components/Artist/Tadeo";
import { Vacoul } from "./components/Artist/Vacoul";
import { Pau } from "./components/Artist/Pau";
import { Yubeili } from "./components/Artist/Yubeili";


const StyledPanel = styled.div`
  padding-top: 70px;
  height: 100%;
  background-color: white;
`; 

function App() {
  return (
    <Fragment>
      <Menu />
        <StyledPanel>
          <Switch>

            <Route exact path="/">
              <Home />
            </Route>

            <Route exact path="/about-acid">
              <AboutAcid />
            </Route>

            <Route exact path="/competences">
              <Competences />
            </Route>
            
            <Route exact path="/roster">
              <Roster />
            </Route>
            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route path="/roster/carlos-mireles"> {/* remove exact */}
              <Carlos />
            </Route>
            <Route  path="/roster/chile"> {/* remove exact */}
              <Chile />
            </Route>
            <Route exact path="/roster/kill-the-clowns">
              <KillTheClowns />
            </Route>
            <Route exact path="/roster/criminal-sounds">
              <CriminalSounds />
            </Route>
            <Route exact path="/roster/madariaga">
              <Madariaga />
            </Route>
            <Route exact path="/roster/tadeo-fernandez">
              <Tadeo />
            </Route>
            <Route exact path="/roster/sebastian-vacoul">
              <Vacoul />
            </Route>
            <Route exact path="/roster/paulina-bujaidar">
              <Pau />
            </Route>
            <Route exact path="/roster/yubeili">
              <Yubeili/>
            </Route>
          </Switch>
        </StyledPanel>
    </Fragment>
  );
}

export default App;
