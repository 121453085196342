import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledResponsiveMenu = styled.div`
  background-color: transparent;
  position: fixed;
  z-index: 999999;
  top: 70px;
  width: 100vw;
  height: 100vh; 
  display: none;
  transition: transform 0.3s ease-in-out;

.container{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: MonumentExtended;  
  /* align-items: end!important;
  justify-content: start!important; */
  padding-left: 60%;
}

  .r-nav{
  font-size: 10px!important;
  color: white;
  text-decoration: none;
  border: 1px solid white;
  /* background-color: rgba(255,255,255,0.1); */
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 10px;
  margin: 10px 20px 0 0;
  text-align: center;
  }

@media (min-width: 375px) and (max-width: 767.98px) { 
  display: block;
  transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-100%)")};
}
`;

const ResponsiveMenu = ({ open, setOpen }) => {
  return (
    <StyledResponsiveMenu open={open}>
      <div className="container">
        <Link className="r-nav" to="/about-acid" onclick={() => open(false)}>
          ABOUT ACID
        </Link>
        <Link className="r-nav" to="/competences">
          COMPETENCES
        </Link>
        <Link className="r-nav" to="/roster">
          ROSTER
        </Link>
        <Link className="r-nav" to="/contact">
          CONTACT
        </Link>
      </div>
    </StyledResponsiveMenu>
  );
};

export default ResponsiveMenu;
