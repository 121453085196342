import React, { useState } from "react";
import styled from "styled-components";
import img1 from "../../assets/img/yubeili/1.jpeg";
import img2 from "../../assets/img/yubeili/2.jpeg";
import img3 from "../../assets/img/yubeili/3.jpeg";
import logo from "../../assets/img/yubeili/logo.png";
import { Row, Col } from "react-bootstrap";
import BookingInquiry from "../BookingInquiry";
import BookingForm from "../BookingForm";
import { Guatemala, CostaRica, Honduras, Argentina, Mexico, ElSalvador, Colombia } from "../../assets/img/flags";

const StyledArtist = styled.div`
  background: #fff;
  background-repeat: no-repeat;
  margin-bottom: 100px;
  padding-bottom: 30px;
  h1 {
    font-family: MonumentExtended;
    font-size: 2rem;
    text-align: center;
    color: #000;
    margin-bottom: 30px;
    padding-top: 30px;
  }

  .imgs-banner {
    width: 100%;
    display: flex;
  }

  .img-1 {
    width: 100%;
    background-image: url(${img2});
    background-size: cover;
    background-position: top;
    height: 300px;
  }
  .img-2 {
    width: 100%;
    background-image: url(${img1});
    background-size: cover;
    background-position: top;
    height: 300px;
  }
  .img-3 {
    width: 100%;
    background-image: url(${img3});
    background-size: cover;
    background-position: top;
    height: 300px;
  }

  .logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo img {
    width: 15vw;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .container {
    width: 100%;
    display: flex;
    padding: 0 200px;
    text-align: center;
    flex-direction: column;
  }
  .container h4 {
    font-family: MonumentExtended;
    color: #000;
  }

  .countries {
    flex-direction: row;
    display: flex;
  }

  .countries p {
    font-family: OpenSans, sans-serif;
    font-size: 1em;
  }

  .line-row {
    border-bottom: 1px solid grey;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .info-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    width: 100%;
    border-bottom: 1px solid grey;
  }
  .bordered-col {
    border-right: 1px solid grey;
    border-left: 1px solid grey;
  }

  .fab {
    color: #000;
    font-size: 30px;
  }
  .clubes h4 {
    text-align: left;
  }
  .last {
    margin-bottom: 30px;
  }

  /* new */
  .direct{
    display: flex;
    flex-direction: row;
  }
  p{
    text-align: left;
    padding-left: 10px;
    margin-top: 0px!important;
    padding-bottom: 5px;
  }

  img{
    margin-inline: 2px;
  }
  .followers{
    text-decoration: none;
    font-size: 26px;
    font-family: MonumentExtended;
    color: #000;
    padding-left: 10px;  
  }
  .direccionRedes{
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
  h4{
    padding: 0px 10px;
  }
  
 

  /* iPhone */
   @media (max-width: 575.98px) {
      h1{
        margin-bottom: 0px!important;
        padding-top: 0px!important;
      }
      .img-1{
        height: 150px!important;
      }
      .img-2{
        height: 150px!important;
      }
      .img-3{
        height: 150px!important;
      }
      .logo img{
        width: 26vw!important;
        margin-top: 0px!important;
        margin-bottom: 0px!important;
      }
      .countries img {
       width: 15px!important;
      }
      h4 {
        font-size: 12px!important;
        line-height: 20px!important;
      }
      .container {
        padding: 0 15px!important; 
      }
      p{
        font-size: 11px!important;
      }
      .followers{
        font-size: 15px!important;
        padding-left: 0px!important;
        
      }
  }
    
  /* iPad */
  @media (min-width: 576px) and (max-width: 1165px) {
    .container {
      padding: 0 100px!important;
    }
    .logo img {
    width: 15vw!important;  
    }
  }

  //bootstrap media queries
  @media (max-width: 768px) {
    .container {
      padding: 0 100px;
    }
  }
  @media (max-width: 576px) {
    .container {
      padding: 0 50px;
    }
  }

  @media (max-width: 485px) {
    h4{
      font-size: 1rem;
    }
  }

  .countries img{
    width: 30px;
  }
`;

export const Yubeili = () => {
  const [showModalForm, setShowModalForm] = useState(false);
  return (
    <>
    <StyledArtist>
      <h1>Roster</h1>

      <div className="imgs-banner">
        <div className="img-1"></div>
        <div className="img-2"></div>
        <div className="img-3"></div>
      </div>
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="container">
        <Row className="line-row">
          <Col xs={12} className="countries">
            <h4>COUNTRIES TOURED _</h4>
            <div>
              <img src={Guatemala} alt="" />
              <img src={CostaRica} alt="" />
              <img src={Honduras} alt="" />
              <img src={Argentina} alt="" />
              <img src={Mexico} alt="" />
              <img src={ElSalvador} alt="" />
              <img src={Colombia} alt="" />

            </div>
          </Col>
        </Row>
        <Row className="info-row">
          <Col xs={4} style={{ padding: "20px" }} className="direccionRedes">
            <a href="https://www.facebook.com/omar.yubeili.7" target="_blank"><i class="fab fa-facebook-square"></i> <span className="followers" >793K</span> </a>
            <a href="https://www.instagram.com/omaryubeili/?hl=es" target="_blank"><i class="fab fa-instagram"></i><span className="followers" >1.3M</span> </a>
          </Col>
          <Col xs={4} className="bordered-col">
            <h4>HIGHLIGHTS</h4>
            <span>
              <p  style={{ paddingRight: "10px" }}>
                Tendencia mundial #6 “Como antes” Mejor Nuevo talento Billboard
                Mex 2018 Canciones en tendencia en mas de 20 Paises (España,
                Uruguay, México, Argentina, Costa Rica, Perú, Ecuador, Colombia,
                etc…) <br/> Disco de Oro Argentina “Vivimos como Capos” Compositor de
                Natanael Cano “Bandolero” Compsitor de Disney Sountracks
                Compositor del tema de Viña del Mar México 2018 “Última Ocasión”
                Más de 20 festivales de Radio
              </p>
            </span>
          </Col>
          <Col xs={4} className="">
            <h4>REMIXES & GIGS</h4>
            <span>
              <p>
                Snow the Product <br/> L-Gante <br/> Ecko <br/> Lefty SM Netto <br/> Peña Blessd <br/> C–Kan
                Omar Montes <br/> The la Planta <br/> Virlan García <br/> Edwin luna y La
                Trakalosa de MTY <br/> Totoy Nanpa Básico <br/> Nyno Vargas <br/> Kaydy Cain <br/> Perro
                Primo
              </p>
            </span>
          </Col>
        </Row>
        <Row className="line-row">
          <Col xs={12} className="clubes">
            <h4>CLUBES _</h4>
            <p>
              Strana • La Santa • Hannah Sun Club • Ragga • Grill Gto • The
              Normal • Janis • Prive • Republica • Dismoor • Cosmo Texas
            </p>
          </Col>
        </Row>
        <Row className="line-row last">
          <Col xs={12} className="clubes">
            <h4>FESTIVALS _</h4>
            <p>
              Latin Music showcase • Palacio de los Deportes • Auditorio
              Nacional • Teatro Metropolitan • Luna Park (Argentina) • Basilica
              de Guadalupe • Auditorio el Cantoral (SACM) • Zocalo de CDMX •
              Foro Sol • Lunario del Auditorio Nacional • Estadio Grl Angel
              Flores, Culiacan • Estadio Azteca • Teleton: Costa Rica, Honduras,
              Guatemala y Costa Rica • Teatro del Pueblo + 10 show en México. •
              Premios TÚ
            </p>
          </Col>
        </Row>
        <BookingInquiry setShow={setShowModalForm} />
      </div>
    </StyledArtist>
    <BookingForm
        showModalForm={showModalForm}
        setShowModalForm={setShowModalForm}
      />
    </>
  );
};
