import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Artista from "../../models/artista.model";
import RosterItem from "./RosterItem";
import BookingInquiry from "../BookingInquiry";
import BookingForm from "../BookingForm";
import { Col, Container, Row } from "rsuite";
import { Link } from "react-router-dom";

import Carlos from "../../assets/img/carlos/1.jpg";
import Chile from "../../assets/img/Chile/1.jpeg";
import Kill from "../../assets/img/kill/1.jpg";
import Criminal from "../../assets/img/criminal/1.png";
import Madariaga from "../../assets/img/madariaga/1.jpeg";
import Tadeo from "../../assets/img/tadeo/1.png";
import Vacoul from "../../assets/img/vacoul/1.jpg";
import Pau from "../../assets/img/pau/3.jpg";
import Yubeili from "../../assets/img/yubeili/1.jpeg";

const StyledContainer = styled.div`
  background: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  color: #000;

  .header {
    width: 100%;
    font-size: 2rem;
    color: #000;
  }

  .header p {
    color: #000;
    text-align: center;
  }
  .text {
    color: #000;
    font-family: MonumentExtended;
    padding: 20px 30px;
    text-align: center;
  }

  .bold {
    font-family: MonumentExtendedBold;
  }

  .regular {
    font-family: MonumentExtended;
  }

  .text-wrap:hover {
    cursor: pointer;
  }

  .text-wrap {
    background: transparent;
    position: absolute;
    z-index: 1000;
    color: white;
    font-size: 25px;
    top: 45%;
    width: 100%;
    height: 40px;
    text-align: center;
    visibility: hidden;
    transition: visibility 0.2s;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    margin-top: 30px;
    position: relative;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-items: center;
  }
  @media (max-width: 1024px) {
    .grid {
      grid-template-columns: repeat(3, 1fr);
    }
    .text {
      font-size: 1rem;
    }
  }
  @media (max-width: 375px) {
    .text {
      font-size: 0.8rem;
    }
  }
  @media (max-width: 540px) {
    .text {
      font-size: 1rem;
    }
  }
  a {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
  }
`;

const Roster = () => {
  const [show, setShow] = useState(false);
  const [artistas, setArtistas] = useState([]);
  const [artista, setArtista] = useState({});
  const [showModalForm, setShowModalForm] = useState(false);

  return (
    <>
      <StyledContainer className="roster-container">
        <div className="header">
          <p className="text bold">
            NO PODEMOS DEFINIR EL <span className="regular">OBJETO</span> O FIN,
            SIN UN <span className="regular">SUJETO </span> QUE
            <span className="regular"> PERCIBA </span> NUESTRAS ACCIONES.
          </p>
        </div>
        <div className="button-container">
          <BookingInquiry setShow={setShowModalForm} />
        </div>
        <div className="grid">
          <Link to="/roster/carlos-mireles">
            <RosterItem
              nombre="Carlos Mireles"
              imagen={Carlos}
              showModalForm={showModalForm}
              setShow={setShowModalForm}
            />
          </Link>
          <Link to="/roster/chile">
            <RosterItem
              nombre="Chile"
              imagen={Chile}
              setShowModalForm={setShowModalForm}
              showModalForm={showModalForm}
            />
          </Link>
          <Link to="/roster/kill-the-clowns">
            <RosterItem
              nombre="Kill the clowns"
              imagen={Kill}
              setShowModalForm={setShowModalForm}
              showModalForm={showModalForm}
            />
          </Link>
          <Link to="/roster/criminal-sounds">
            <RosterItem
              nombre="Criminal Sounds"
              imagen={Criminal}
              setShowModalForm={setShowModalForm}
              showModalForm={showModalForm}
            />
          </Link>
          <Link to="/roster/madariaga">
            <RosterItem
              nombre="Madariaga"
              imagen={Madariaga}
              setShowModalForm={setShowModalForm}
              showModalForm={showModalForm}
            />
          </Link>
          <Link to="/roster/tadeo-fernandez">
            <RosterItem
              nombre="Tadeo Fernández"
              imagen={Tadeo}
              setShowModalForm={setShowModalForm}
              showModalForm={showModalForm}
            />
          </Link>
          <Link to="/roster/sebastian-vacoul">
            <RosterItem
              nombre="Sebastián Vacoul"
              imagen={Vacoul}
              setShowModalForm={setShowModalForm}
              showModalForm={showModalForm}
            />
          </Link>
          <Link to="/roster/paulina-bujaidar">
            <RosterItem
              nombre="Paulina Bujaidar"
              imagen={Pau}
              setShowModalForm={setShowModalForm}
              showModalForm={showModalForm}
            />
          </Link>
          <Link to="/roster/yubeili">
            <RosterItem
              nombre="Yubeili"
              imagen={Yubeili}
              setShowModalForm={setShowModalForm}
              showModalForm={showModalForm}
            />
          </Link>
        </div>
      </StyledContainer>
      <BookingForm
        showModalForm={showModalForm}
        setShow={setShowModalForm}
      />
    </>
  );
};

export default Roster;
