import React, { useEffect, useState } from "react";
import { WhiteLogo } from "../assets";
import { Link, useLocation } from "react-router-dom";
import ResponsiveMenu from "./ResponsiveMenu.jsx"
import styled from "styled-components";
import { FaBars } from "react-icons/fa";

const StyledNav = styled.nav`
  background-color: #000;
  position: fixed;
  z-index: 9999999;
  width: 100%;
  padding: 20px 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
  color: white;

  .logo {
    width: 125px;
  }

  .menu {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    margin: 0;
  }

  li {
    list-style: none;
    margin-left: 30px;
    font-size: 16px;
    transition: 0.5s;
  }

  .a-nav {
    display: block;
    position: relative;
    text-decoration: none;
    color: white;
    transition: all 0.3s;
    padding: 2px;
    border: 1px solid white;
    border-radius: 2px;
    padding: 5px; 
  }

  ol:hover .a-nav{
    filter: blur(5px);
    opacity: 0.5;
  }

  ol .a-nav:hover {
    transform: scale(1.5);
    opacity: 1;
    filter: blur(0);
  }

  .a-nav:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid white;
    border-radius: 2px;
    transition: 0.3s;
    transform: scale(0);
    z-index: -1;
  }

  .a-nav:hover:before{
    transform: scale(1);
  }

  .hamburger{
    width: 35px;
    height: 30px;
    display: flex;
    justify-content: left;
    align-items: center; 
    position: relative;
    display: none;
  }
  
  .hamburger:hover{
    cursor: pointer; 
  }
  
  .bar{
    height: 5px;
    background-color: white;
    width: 75%; 
    border-radius: 2px;
  }
  
  .p-bar::before,
  .p-bar::after{
    content: '';
    height:  5px;
    background-color: white;
    width: 100%;
    position: absolute;
    border-radius: 2px;
  }

  .p-bar::before{
    transform: translateY(-13px);
  }  

  .p-bar::after{
    transform: translateY(13px);
    width: 50%;
  }

  
/* MEDIA QUERIES */

@media (max-width: 767.98px) { 
  .hamburger{
    display: flex;
  }
  .menu{
    display: none;
 }
}
@media (min-width: 375px) and (max-width: 991.98px) { 
  padding: 20px 50px;
}
`;

const Menu = () => {
  const [open, setOpen] = useState(false);

  const location = useLocation()

  useEffect(() => {
    setOpen(false)
  }, [location.pathname])

  return (
    <>
    <StyledNav className="nav">
      <Link to="/">
        <img className="logo" src={WhiteLogo} alt="" />
      </Link>
      <ol className="menu">
        <li>
          <Link to="/about-acid" className="a-nav">ABOUT ACID</Link>
        </li>

        <li>
          <Link to="/competences" className="a-nav">COMPETENCES</Link>
        </li>
        
        <li>
          <Link to="/roster" className="a-nav">ROSTER</Link>
        </li>

        <li>
          <Link to="/contact" className="a-nav">CONTACT</Link>
        </li>
      </ol>
      <div className="hamburger" open={open} onClick={() => setOpen(!open)}>
        {/* <div className="bar p-bar"> 
        
        </div> */}
        <FaBars style={{ height: "25px", width: "25px"}} />
      </div>
    </StyledNav>
    <ResponsiveMenu open={open} setOpen={setOpen}/>
    </> 
  );
};
export default Menu;
