import React, { useState } from "react";
import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  ButtonToolbar,
  Alert,
} from "rsuite";
import styled from "styled-components";
import emailjs from "emailjs-com";
import { ContactBg } from "../../assets/img/index";

const StyledContact = styled.div`
  background-image: url("${ContactBg}");
  background-position: left left;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  background-attachment: fixed;
  position: absolute;
  width: 100%;

  .bold {
    font-family: "MonumentExtended";
  }
  .maindiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .seconddiv {
    width: 100%;

    /* box-shadow: 20px 20px 52px #a5a5a5; */
    display: flex;
    justify-content: space-around;
    margin-block-start: 0%;
    /* margin-block-end: 5%; */
  }

  .thirddiv {
    padding: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
  }

  a {
    padding-bottom: 2%;
    color: #727272;
  }

  h2 {
    font-family: "Lato", sans-serif;
    font-style: normal;
    color: #000000;
  }

  .hdos {
    font-family: "Lato", sans-serif;
    font-weight: lighter;
    color: #2b2b2b;
    width: 50%;
    font-size: 30px;
  }

  .contenido {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 50px 0;
  }

  .contenidoChild {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .fontSize1 {
    font-size: 15px;
    color: #ffffff;
  }
  .fontSize2 {
    font-size: 40px;
    color: #ffffff;
  }

  .fontTitle {
    font-family: "Stick No Bills", sans-serif;
    font-weight: bold;
  }

  .containerDudas {
    justify-content: center;
    display: flex;
  }

  .rs-btn {
    font-weight: bold;
  }

  .rs-btn-primary {
    background-color: transparent;
  }

  h2 {
    line-height: 30px !important;
  }

  .rs-input {
    background-color: transparent !important;
    border: 1px solid #fff !important;
    color: #fff;
  }
  .rs-control-label {
    font-size: 15px !important;
    color: #fff !important;
    font-weight: bold !important;
  }
  .rs-btn-toolbar {
    display: flex;
    justify-content: center;
  }
  .textborder {
    -webkit-text-stroke: 1px #ffffff;
    color: transparent;
  }

  .titulos {
    width: 100%;
  }

  .espaciado {
    margin-top: 4%;
  }

  .derechos {
    color: white;
    font-size: 9px;
    text-align: center;
    padding-bottom:3%;
  }

  /* iPhone */
  @media (max-width: 575.98px) {

    .espaciado{
    margin-top: 7%;
    }

    .thirddiv {
      width: 100% !important;
    }

    .derechos {
      color: white;
      font-size: 9px;
      text-align: center;
      padding-top: 5%;
    }
  }
  @media (min-width: 576px) and (max-width: 1165px) {
    .derechos {
      color: white;
      font-size: 9px;
      text-align: center;
      padding-top: 5%;
      padding-bottom: 3% !important;
    }
  }
`;

const Contact = () => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");

  const senEmail = () => {
    emailjs
      .send(
        "default_service",
        "template_9ohjywk",
        { name, message, email },
        "user_hRcpIroA17OcIRozSopey"
      )
      .then(
        (response) => {
          setMessage("Ejemplo");
          setName("");
          setEmail("");
          Alert.success("Tu mensaje se envió correctamente");
        },
        (err) => {
          Alert.warning("Hubo error: " + err);
        }
      );
  };

  return (
    <StyledContact>
      <div className="maindiv">
        <div className="seconddiv">
          <div className="thirddiv">
            <div className="titulos">
              {/* Form dudas */}
              <h2 className="fontSize2 bold containerDudas textborder">
                CONTACTO
              </h2>
              <h2
                className="fontSize1 bold containerDudas espaciado"
                style={{ marginBottom: "15%" }}
              >
                ¿TIENES ALGUNA DUDA?
              </h2>

              <div className="containerNL">
                <Form fluid>
                  <FormGroup>
                    <ControlLabel className="bold">NOMBRE</ControlLabel>
                    <FormControl
                      value={name}
                      onChange={(value) => setName(value)}
                      name="name"
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel className="bold">E MAIL</ControlLabel>
                    <FormControl
                      value={email}
                      onChange={(value) => setEmail(value)}
                      name="email"
                      type="email"
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel className="bold">MENSAJE</ControlLabel>
                    <FormControl
                      value={message}
                      onChange={(value) => setMessage(value)}
                      rows={5}
                      name="textarea"
                      componentClass="textarea"
                    />
                  </FormGroup>
                  <FormGroup>
                    <ButtonToolbar>
                      <Button
                        onClick={() => senEmail()}
                        appearance="primary"
                        className="subtle bold"
                      >
                        ENVIAR
                      </Button>
                    </ButtonToolbar>
                  </FormGroup>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className="derechos">©2021 ALL RIGHTS RESERVED ACID</div>
      </div>
    </StyledContact>
  );
};

export default Contact;
