import React from "react";
import styled from "styled-components";

const StyledItem = styled.div`
  width: 250px;
  height: 400px;
  position: relative;
  z-index: 1;
  .hex-item {
    width: 100%;
    background-image: url(${(props) => props.imagen});
    height: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: -1;
  }

  .text-card {
    position: absolute;
    color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    visibility: hidden;
    font-family: MonumentExtended;
  }

  h3{
    font-size: 1.5rem;
  }

  .text-card h4 {
    font-family: OpenSans, sans-serif;
    font-size: 0.5em;
  }

  :hover .text-card {
    visibility: visible;
    background: rgba(0, 0, 0, 0.5);
  }

  :hover {
    cursor: pointer;
  }
  //media querie max 768px
  @media (max-width: 768px) {
    width: 100%;
    height: 300px;
 h3{
      font-size: 1em;
    }
  }
  @media (max-width: 624px) {
    width: 100%;
    height: 250px;
    h3 {
      font-size: 40px;
    }
  }
  @media (max-width: 475px) {
    width: 100%;
    height: 200px;
    h3 {
      font-size: 15px;
    }
  }

`;

const RosterItem = ({ nombre, imagen }) => {
  return (
    <StyledItem imagen={imagen}>
      <div className="text-card">
        <h3>{nombre}</h3>
        <h4>VER MÁS</h4>
      </div>
      <div className="hex-item"></div>
    </StyledItem>
  );
};

export default RosterItem;
