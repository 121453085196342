import React from "react";
import styled from "styled-components";
import { fondo } from "../../assets/img/index";
import logo from "../../assets/img/logotipo_BLANCO.png";
import f1 from "../../assets/img/fondo1.jpg";

const StyledAboutUs = styled.div`
  background-image: url("${fondo}");
  background-size: cover;
  width: 100%;
  background-position: center center;
  position: absolute;
  min-height: 100%;

  .StyleTitulo {
    color: white;
    font-family: "MonumentExtended";
    font-size: 22px;
    text-align: right;
    padding-top: 4%;
    margin-right: 5%;
  }

  .styleParrafo {
    color: white;
    font-family: "MonumentExtended";
    font-size: 8px;
    text-align: justify;
    margin-left: 45%;
    margin-right: 6%;
  }

  .styleParrafob {
    font-size: 8px;
    margin-left: 5%;
    margin-right: 50%;
    text-align: justify;
    color: white;
    font-family: "MonumentExtended";
  }

  .StyleTitulob {
    font-size: 25px;
    padding-top: 5%;
    margin-left: 1%;
    text-align: justify;
    color: white;
    font-family: "MonumentExtended";
  }

  .styleParrafoc {
    font-size: 8px;
    padding-left: 40%;
    padding-right: 15%;
    padding-top: 5%;
    text-align: justify;
    color: white;
    font-family: "MonumentExtended";
    position: relative;
  }

  .styleParrafod {
    font-size: 8px;
    margin-left: 40%;
    margin-right: 15%;
    margin-top: 2%;
    text-align: justify;
    color: white;
    font-family: "MonumentExtended";
  }

  .styleParrafoe {
    font-size: 8px;
    margin-left: 10%;
    margin-right: 35%;
    margin-top: 7%;
    text-align: justify;
    color: white;
    font-family: "MonumentExtended";
  }

  .imagen1 {
    width: 35%;
    height: 10%;
    float: right;
    border-radius: 30px;
    border: solid 2px #fff;
    margin-right: 5%;
    margin-top: 2%;
  }

  .imagen2 {
    width: 35%;
    height: 10%;
    float: left;
    border-radius: 30px;
    border: solid 2px #fff;
    margin-left: -20%;
    margin-top: 10%;
  }

  .logo1 {
    width: 40%;
    height: 20%;
    margin-right: 0%;
    margin-top: 8%;
    margin-left: 16%;
  }

  .derechos {
    color: white;
    font-size: 9px;
    text-align: center;
    padding-top: 2%;
    margin-right: 2%;
  
  }

  /* iPhone */
  @media (max-width: 575.98px) {
    .derechos {
    padding-bottom: 10%;
    margin-top:4%;
  }
  }

  /* iPad */
  @media (min-width: 576px) and (max-width: 1165px) {
    .derechos {
      font-size: 13px;
      text-align: center;
      padding-top: 13%;
    }

    .imagen1 {
      width: 20%;
      height: 8%;
      float: right;
      margin-right: 5%;
      margin-top: -4%;
    }

    .imagen2 {
      width: 20%;
      height: 8%;
      float: left;
      margin-left: -10%;
      margin-top: 3%;
    }

    .logo1 {
      width: 22%;
      height: 7%;
      float: center;
      margin-left: 25%;
      margin-top: 4%;
    }

    .StyleTitulo {
      font-size: 45px;
      padding-top: 2%;
      margin-right: 5%;
    }

    .styleParrafo {
      font-size: 15px;
      margin-left: 45%;
      margin-right: 6%;
    }

    .styleParrafob {
      font-size: 15px;
      margin-left: 5%;
      margin-right: 50%;
    }

    .StyleTitulob {
      font-size: 45px;
      padding-top: 2%;
      margin-left: 5%;
    }

    .styleParrafoc {
      font-size: 15px;
      padding-left: 40%;
      padding-right: 15%;
      padding-top: 1%;
      position: relative;
    }
    .styleParrafod {
      font-size: 15px;
      margin-left: 40%;
      margin-right: 15%;
      margin-top: 2%;
    }

    .styleParrafoe {
      font-size: 15px;
      margin-left: 10%;
      margin-right: 35%;
      margin-top: 2%;
    }
  }

  /* Desktop style={{ height:'110%', width:'50%' , marginLeft:'35%' , marginRight:'30%' , marginTop:'10%' }} */
  @media (min-width: 1165px) {
    .derechos {
      padding-bottom: 5%;
    }

    .imagen1 {
      width: 20%;
      height: 8%;
      float: right;
      margin-right: 5%;
      margin-top: -4%;
    }

    .imagen2 {
      width: 20%;
      height: 8%;
      float: left;
      margin-left: -10%;
      margin-top: 3%;
    }

    .logo1 {
      width: 22%;
      height: 7%;
      float: center;
      margin-left: 25%;
      margin-top: 4%;
    }

    .StyleTitulo {
      font-size: 45px;
      padding-top: 2%;
      margin-right: 5%;
    }

    .styleParrafo {
      font-size: 15px;
      margin-left: 45%;
      margin-right: 6%;
    }

    .styleParrafob {
      font-size: 15px;
      margin-left: 5%;
      margin-right: 50%;
    }

    .StyleTitulob {
      font-size: 45px;
      padding-top: 2%;
      margin-left: 5%;
    }

    .styleParrafoc {
      font-size: 15px;
      padding-left: 40%;
      padding-right: 15%;
      padding-top: 1%;
      position: relative;
    }

    .styleParrafod {
      font-size: 15px;
      margin-left: 40%;
      margin-right: 15%;
      margin-top: 2%;
    }

    .styleParrafoe {
      font-size: 15px;
      margin-left: 10%;
      margin-right: 35%;
      margin-top: 2%;
    }
  }
`;

const Contact = () => {
  return (
    <StyledAboutUs>
      <div className="StyleTitulo">¿QUÍENES SOMOS?</div>
      <div className="styleParrafo">
        SOMOS GUÍAS Y OBSERVACIONES DE COMO LAS EXPERIENCIAS Y EL 
        ENTRETENIMIENTO PUEDE GUIAR AL CUERPO A UN REFLEJO DE LO QUE SIENTE EN EL
        MOMENTO. CREAMOS EMOCIONES
      </div>

      <div className="StyleTitulob">¿QUE BUSCAMOS?</div>
      <div>
        {" "}
        <img src={f1} className="imagen1" />
      </div>
      <div className="styleParrafob">
        LA MÚSICA Y EL ENTRETENIMIENTO COMO MANERA DE EXPRESIÓN. USAMOS LA VOZ
        HUMANA O LA PERCUSIÓN CORPORAL A MANERA DE COMUNICACIÓN, NO DEJA UNA
        HUELLA FISICA SINO EMOCIONAL.
      </div>

      <div>
        <img className="logo1" src={logo} />
        <img className="imagen2" src={f1} />

        <div className="styleParrafoc">
          ACID POR LAS EMOCIONES QUE SIENTES CUANDO DESCUBRES ALGO NUEVO Y
          COMO VIBRA TODO TU CUERPO POR ESAS SENSACIONES.
        </div>

        <div className="styleParrafod">
          QUEREMOS QUE NOS RECUERDES POR SER POCO COMUNES, PERO SIEMPRE DEJAMOS
          HUELLA.
        </div>
      </div>

      <div className="styleParrafoe">
        VAM INSPIRANDO EN EL SEGUNDO CHAKRA, BUSCAMOS ELEVAR NUESTROS
        SENTIDOS, EQUILIBRAR LA MENTE Y CUERPO PARA BUSCAR Y ENTRAR EN SINTONÍA
        CON LO QUE STAMOS VIVIENDO EN EL MOMENTO
      </div>

      <div className="derechos">©2021 ALL RIGHTS RESERVED ACID</div>
    </StyledAboutUs>
  );
};

export default Contact;
