import firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyC3BRvFVANPm_QCP00UFBZ_1Jap8-4A3RY",
    authDomain: "acidweb-5ab9c.firebaseapp.com",
    projectId: "acidweb-5ab9c",
    storageBucket: "acidweb-5ab9c.appspot.com",
    messagingSenderId: "253760928853",
    appId: "1:253760928853:web:4356d5d80545cc1c33e8dc",
    measurementId: "G-MXRJ1NLNRG"
}


const app = firebase.initializeApp(firebaseConfig)

export default app;
