import React, { useEffect, useState, createRef } from "react";
import styled from "styled-components";
import {
  Col,
  Grid,
  Row,
  Input,
  SelectPicker,
  Alert,
  Button,
  ButtonToolbar,
} from "rsuite";
import Artistas from "../models/artista.model";
import emailjs from "emailjs-com";

const StyledBookingForm = styled.div`
visibility: ${(props) => (props.showModalForm ? "visible" : "hidden")};
  .overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #444;
    opacity: 0.5;
    top: 0;
    z-index: 5000;
  }

  .modal {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rs-picker-menu {
    z-index: 10000 !important;
  }

  .container {
    color: #444;
    padding: 15px;
    border-radius: 15px;
    background-color: #fff;
    z-index: 10000;
    opacity: 1;
    width: 90%;
    max-width: 500px;
  }

  h3 {
    font-family: MonumentExtended;
    text-align: center;
    color: black;
  }
`;

const BookingForm = ({ close, setShow, showModalForm }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [artista, setArtista] = useState("");
  const [artistas, setArtistas] = useState([]);
  const [phone, setPhone] = useState("");
  const [budget, setBudget] = useState("");
  const [date, setDate] = useState("");

  const senEmail = () => {
    emailjs
      .send(
        "default_service",
        "template_om2pp7i",
        { name, email, phone, artista, budget, date },
        "user_hRcpIroA17OcIRozSopey"
      )
      .then(
        (response) => {
          setName("");
          setEmail("");
          setPhone("");
          setBudget("");
          setDate("");
          setArtista("");
          Alert.success("Tu mensaje se envio correctamente");
        },
        (err) => {
          Alert.warning("Hubo error: " + err);
        }
      );
  };
  useEffect(() => {
    (async () => {
      let arts = await Artistas.getArtista();

      arts = arts.map((value) => ({
        label: value.nombre,
        value: value.nombre,
        role: "Master",
      }));

      console.log(arts);

      setArtistas(arts);
    })();

    setTimeout(() => {
      document
        .querySelector(".rs-btn.rs-btn-default.rs-picker-toggle")
        .addEventListener("click", () => {
          setTimeout(() => {
            const doc = document.querySelector(".rs-picker-menu");
            if (doc) {
              doc.style.zIndex = 100000;
              doc.style.maxHeight = "300px";
              console.log("click");
            }
          }, 500);
        });
    }, 2000);
  }, []);

  const closeD = () => {
    setShow(false);
    console.log("click")
  };

  return (
    <StyledBookingForm showModalForm={showModalForm}>
      <div className="overlay"  onClick={closeD}></div>
      <div className="modal" >
        <div className="container">
          <Grid fluid>
            <Row>
              <h3>Booking Inquiry</h3>
              <Col md={12}>
                <label htmlFor="name">Nombre</label>
                <Input
                  placeholder="Nombre"
                  id="name"
                  value={name}
                  onChange={(value) => setName(value)}
                />
                <label htmlFor="tel">Teléfono</label>
                <Input
                  placeholder="Teléfono"
                  id="tel"
                  value={phone}
                  onChange={(value) => setPhone(value)}
                />
              </Col>
              <Col md={12}>
                <label htmlFor="email">Correo</label>
                <Input
                  placeholder="Correo"
                  id="correo"
                  value={email}
                  onChange={(value) => setEmail(value)}
                />
              </Col>
            </Row>
          </Grid>
          <hr />
          <Grid fluid>
            <Row>
              <Col md={12}>
                <label>Artista</label>
                <SelectPicker
                  className="input"
                  data={artistas}
                  block
                  value={artista}
                  onChange={(value) => setArtista(value)}
                />
              </Col>
              <Col md={12}>
                <label>Presupuesto</label>
                <Input
                  placeholder="$"
                  value={budget}
                  onChange={(value) => setBudget(value)}
                />
              </Col>
              <Col md={24}>
                <label>Fecha</label>
                <Input
                  placeholder="Fecha"
                  value={date}
                  onChange={(value) => setDate(value)}
                />
              </Col>
              <Col
                md={24}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <ButtonToolbar>
                  <Button
                    appearance="default"
                    active
                    onClick={() => senEmail()}
                  >
                    Enviar
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </Grid>
        </div>
      </div>
    </StyledBookingForm>
  );
};
export default BookingForm;
